import React from 'react';


/**
 * routers
 */
export default function(Loadable) {
  return [
    {
      name:      'licenseImages',
      path:      '/licenseImages',
      exact:     true,
      component: Loadable({
        loader:  () => import('../containers/licenseImages/view/licenseImages'),
        loading: () => <div />
      })
    }
  ];
}
