import request from '../utils/request';
import env from '../config/env';
import { storageGet } from '../utils/storage';

/**
 * get onboarding info
 */
export async function getOnboarding(userId) {

  try {
    
    const result = await request({
      url:    `${env.server}/v1/accounts/${userId}/onboarding`,
      method: 'get'
    });

    return result;
  } catch (error) {
    if(error.code === "not-found"){
      throw new Error('You may have an account not registered through onboading')
    }
    throw error
  }
}

/**
 * update onboarding info
 */
export async function updateOnboarding(data,id) {

  const user = storageGet('user');
  const result = await request({
    url:    `${env.server}/v1/accounts/${user._id}/onboarding/${id}`,
    method: 'put',
    data
  });

  return result;
}


