/**
 * filters/lang.js
 *
 * @author  Leo <lixiang@ricepo.com>
 * @license 2020-21 (C) Ricepo LLC. All Rights Reserved.
 */
import intl from 'react-intl-universal';
import _ from 'lodash';

/**
 * Translate filter
 */
function lang(name, params, fallback) {
  const localeOptions = intl.getInitOptions();
  const currentLocale = localeOptions.currentLocale || 'en-US';


  /* Return '' if no name */
  if (_.isEmpty(name)) {
    return '';
  }

  /* Langs pre-defined objects */
  if (_.isObject(name)) {
    return _.get(name, currentLocale) || _.get(name, 'zh-CN') || name;
  }

  return intl.get(name, params) || fallback;
}

export default lang;

/**
 * get current lang
 */
export function getCurrentLang() {
  const localeOptions = intl.getInitOptions();
  const currentLocale = localeOptions.currentLocale || 'en-US';

  return currentLocale;
}

