import request from '../utils/request';
import env from '../config/env';

/**
 * check login
 */
export async function checkLogin(data) {

  const result = await request({
    url:    `${env.server}/v1/auth/driver`,
    method: 'post',
    data
  });

  return result;
}

/**
 * renew token
 */
export async function renewToken(data) {


  const result = await request({
    url:    `${env.server}/v1/auth`,
    method: 'get',
    data
  });

  return result;
}


