import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { withStyles } from '@material-ui/core/styles';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const useStyles = (theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(20),
    },
  },
});


export class CustomizedSnackbars extends React.Component{

  /**
   * Define prop types
   */
  static propTypes = {  };

  /**
   * state
   */
  state = {
    errorModalState: {
      visible: false,
      msg:     '',
      type:    'normal'
    }
  };

  /**
   * componentWillReceiveProps
   */
  componentWillReceiveProps(nextProps) {

    this.setState({ errorModalState: nextProps.errorModalState });
  }

  render(){
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <Snackbar 
          open={this.state.errorModalState.visible}
          onClose={this.props.closeModal}
          autoHideDuration={6000}
          >
          <Alert 
            onClose={this.props.closeModal} 
            severity={this.state.errorModalState.severity || 'error'}>
            {this.state.errorModalState.msg}
          </Alert>
        </Snackbar>
      </div>
    );
  }
}

const AlertModal = connect(
  state => state,
  dispatch => bindActionCreators({  }, dispatch)
)(CustomizedSnackbars);

export default withStyles(useStyles)(AlertModal);