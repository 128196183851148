import _ from 'lodash';
import { storageGet, storageSet } from './storage';
import { getOnboardingInfo } from '../containers/basicInfo/state/actions';


/**
 * return the path
 */
export default async function jumpTo(){

  const user = storageGet('user');

  /**
   * if not login
   */
  if(!user){
    return 'main'
  }

  let onboarding = storageGet('onboarding');
  let main = storageGet('main');
  
  if(!onboarding){
    onboarding = await getOnboardingInfo(user._id);
    storageSet('onboarding', onboarding);
    storageSet('main', _.get(onboarding,'main'));
  }

  if(!onboarding || (!onboarding.main && !main)){
    return 'main'
  }

  if(!onboarding.basic){
    return 'basic'
  }

  if(!onboarding.background){
    return 'background'
  }

  /* const type = _.get(onboarding,'background.vehicle.type')

  if(type === 'car'){
    if(!_.get(onboarding,'licenseImages',[]).length){
      return 'licenseImages'
    }
    if(!_.get(onboarding,'insuranceImages',[]).length){
      return 'insuranceImages'
    }
  } */
  
  /**
   * default is home
   */
  return 'home';
}
 
