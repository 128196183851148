import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { hideSupportModal,customerSupport } from '../state/actions';
import lang from '../../../utils/filter/lang';
import { showError } from '../../../containers/home/state/actions';

function Support(props) {

  const [subject, setSubject] = useState('');
  const handleChange = async e => {
    setSubject(e.currentTarget.value)
  }
  async function handleSubmit (){

    if(!subject){
      return props.showError('Please type your content!');
    }
    props.customerSupport({ subject, type:'onboarding-issue' })
    props.hideSupportModal()
    setSubject('')
  }

  return (
    
    <div>
      <Dialog open={props.supportModalState.visible} onClose={props.hideSupportModal} aria-labelledby="customer-support">
        <DialogTitle id="customer-support">{props.title}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {props.content}
          </DialogContentText>
          <TextField
            autoFocus
            multiline
            rows={4}
            fullWidth
            variant="outlined"
            required
            onChange={handleChange}
            value={subject}
          />
          <br/>
          <br/>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            onClick={handleSubmit}
          >
            {lang('submit')}
          </Button>
        </DialogContent>
      </Dialog>
    </div>
  );
}
export default connect(
  state => state.support,
  dispatch => bindActionCreators({ 
    hideSupportModal,
    showError,
    customerSupport 
  }, dispatch)
)(Support);