import axios from 'axios';
import _ from 'lodash';
import pathToRegexp from 'path-to-regexp';
import packageJson from '../../package.json';
import CustomError from './error';
import { storageGet } from './storage';

/* 30 sec timeout */
axios.defaults.timeout = 30000;

/**
 * request
 */
const fetch = (options) => {

  let { url } = options;

  const { data = {}, headers = {}, method } = options;

  /* Append User Token if any */
  /* Has to get store instance in the function, putting on top will cause error */

  const token = _.get(storageGet('user'), 'token');

  if (token) {
    headers.Authorization = `JWT ${token}`;
  }

  headers['Content-Type'] = 'application/json';

  /* cache */
  headers['Cache-Control'] = 'no-cache';

  /* user agent header */
  headers['X-Ricepo-Client'] = `Onboarding/${packageJson.version}`;

  /* Clone request body data */
  const cloneData = _.cloneDeep(data);

  switch (_.toLower(method)) {
    case 'get':
      return axios.get(url, { params: cloneData, headers });
    case 'delete':
      return axios.delete(url, { data: cloneData, headers });
    case 'post':
      return axios.post(url, cloneData, { headers });
    case 'put':
      return axios.put(url, cloneData, { headers });
    case 'patch':
      return axios.patch(url, cloneData, { headers });
    default:
      return axios(options);
  }
};

/**
 * Default request function
 */
export default async function request(options) {

  try {
    const res = await fetch(options);
    let data = _.get(res, 'data', {});

    /* convert list to object */
    if (_.isArray(data)) {
      data = { list: data };
    }

    return data;
  } catch (error) {

    throw new CustomError(error);
  }
}
