import _ from 'lodash';
import * as ActionType from '../../../actions/actionTypes';

/* init state */
const initialState = {
  supportModalState: {
    visible: false
  }
};

export default (state = initialState, payload) => {

  switch (payload.type) {

    /* show error */
    case ActionType.SHOW_SUPPORT_MODAL:
      return {
        ...state,
        supportModalState: payload.supportModalState,
        type:          payload.type
      };

      /* hide error */
    case ActionType.HIDE_SUPPORT_MODAL:
      return {
        ...state,
        supportModalState: {
          visible: false
        }
      };


    default:
      return state;
  }
};
