let environment = {
   
};

if (process.env.NODE_ENV === 'development') {
  environment = {
    server:    'https://dev.isengard.ricepo.com',
    s3: 'test-images', 
  };
} else if (process.env.NODE_ENV === 'production') {
  environment = {
    server:    'https://external.isengard.ricepo.com',
    s3: 'onboarding-images',
  };
} else {
  environment = {
    s3: 'test-images', // aws s3 
    // eslint-disable-next-line line-comment-position
    // server:    'https://staging.isengard.ricepo.com',
    // eslint-disable-next-line line-comment-position
    // server:    'https://dev.isengard.ricepo.com',
    // server:    'http://192.168.50.124:3000',
    server:    'http://192.168.50.74:3000',
    // server:    'http://localhost:3000',

    // server:    'http://7cf7be74e123.ngrok.io'
  };

}

module.exports = environment;
