import _ from 'lodash';
import * as ActionType from '../../../actions/actionTypes';

/* init state */
const initialState = {
  errModalState: {
    visible: false,
    msg:     ''
  }
};

export default (state = initialState, payload) => {

  switch (payload.type) {

    /* show error */
    case ActionType.SHOW_ERROR:
      return {
        ...state,
        errModalState: payload.errModalState,
        type:          payload.type
      };

      /* hide error */
    case ActionType.HIDE_ERROR:
      return {
        ...state,
        errModalState: {
          visible: false,
          msg:     ''
        }
      };


    default:
      return state;
  }
};
