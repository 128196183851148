import React from 'react';


/**
 * routers
 */
export default function(Loadable) {
  return [
    {
      name:      'insuranceImages',
      path:      '/insuranceImages',
      exact:     true,
      component: Loadable({
        loader:  () => import('../containers/insuranceImages/view/insuranceImages'),
        loading: () => <div />
      })
    }
  ];
}
