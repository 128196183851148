import thunk from 'redux-thunk'
import { createHashHistory } from 'history'
import { applyMiddleware, compose, createStore } from 'redux'
import { routerMiddleware } from 'connected-react-router'
import createRootReducer from '../reducers/index'

export const history = createHashHistory({
  hashType: 'slash'
})

const initialState = {}
export default (preloadedState = initialState) => {
  const store = createStore(
    createRootReducer(history),
    preloadedState,
    compose(
      applyMiddleware(
        thunk,
        routerMiddleware(history)
      )
    )
  )
  return store
}