/**
 * All Actions Must be in following format
 *
 * VERB_NOUN
 *
 * VERB must be selected from the following options:
 * CHANGE: update from user input: select / type
 * LOAD:   save data from the remote request
 * UPDATE: update existing data as result of remote PUT request
 * SWITCH: switch between error and data on page
 * SAVE:   save data to async storage
 * SHOW:   display extra info in the page
 * HIDE    hide the extra info
 *
 * NOUN must match the actual entity / view name
 *
 */

/**
 * Login
 */
export const SAVE_USER = 'SAVE_USER';

export const SAVE_PHONE = 'SAVE_PHONE';

export const CLEAR_VCODE = 'CLEAR_VCODE';

export const TOGGLE_MODE = 'TOGGLE_MODE';

export const VCODE_RESTART_TIME = 'VCODE_RESTART_TIME';

export const RESTART_COUNTDOWN = 'RESTART_COUNTDOWN';

/**
 * Login
 */
export const SAVE_ONBOARDING = 'SAVE_ONBOARDING';

/**
 * Notification
 */
export const SHOW_ERROR = 'SHOW_ERROR';

export const HIDE_ERROR = 'HIDE_ERROR';


/**
 * Loading
 */
export const SHOW_LOADING = 'SHOW_LOADING';

export const HIDE_LOADING = 'HIDE_LOADING';

/**
 * Language
 */
export const CHANGE_LANGUAGE = 'CHANGE_LANGUAGE';


/**
 * Support 
 */
export const SHOW_SUPPORT_MODAL = 'SHOW_SUPPORT_MODAL';

export const HIDE_SUPPORT_MODAL = 'HIDE_SUPPORT_MODAL';