import Loadable from 'react-loadable';
import home from './home';
import main from './main';
import login from './login';
import basicInfo from './basicInfo';
import backgroundInfo from './backgroundInfo';
import licenseImages from './licenseImages';
import insuranceImages from './insuranceImages';

const config = [
  ...(home(Loadable)),
  ...(main(Loadable)),
  ...(login(Loadable)),
  ...(basicInfo(Loadable)),
  ...(backgroundInfo(Loadable)),
  ...(licenseImages(Loadable)),
  ...(insuranceImages(Loadable))
];

export default config;
