import _ from 'lodash';
import { push } from 'connected-react-router';
import * as ActionType from '../../../actions/actionTypes';
import { checkLogin } from '../../../services/login';
import { getOnboarding } from '../../../services/onboarding';
import { showError } from '../../home/state/actions';

import { storageGet,storageSet } from '../../../utils/storage';
import  jumpTo from '../../../utils/jumpTo'


/**
 * redirect to main
 */
export const forwardMain = () => async(dispatch, getState) => {

  try {
    const path = '/main';
    dispatch(push(path));
  } catch (error) {

    dispatch(showError(error.message));

  }

};

/**
 * reset restart status
 */
export function resetRestart() {

  return async(dispatch) => {

    dispatch({ type: ActionType.RESTART_COUNTDOWN, restart: false });
  };
}


/**
 * reset restart status
 */
export function resetCountdown() {

  return async(dispatch) => {

    dispatch({ type: ActionType.VCODE_RESTART_TIME, countdown: 0 });
  };
}


/**
 * reset restart status
 */
export function toggleMode(mode) {

  return async(dispatch) => {

    dispatch({ type: ActionType.TOGGLE_MODE, mode });
  };
}

/**
 * login out
 */
export function logOut() {

  return async(dispatch, getState) => {
    /* notification appbar change login to logout  */
    dispatch({ type: ActionType.SAVE_USER, user:null });

    const path = '/main';
    dispatch(push(path));
  };
}

/**
 * login with vcode
 */
export function checkLoginWithVcode(phone, vcode) {

  return async(dispatch, getState) => {

    /* show loading */
    dispatch({ type: ActionType.SHOW_LOADING });

    try {

      /* check login */
      const user = await checkLogin({ phone, vcode });

      /* save token and user info  */
      dispatch({ type: ActionType.SAVE_USER, user });

      storageSet('user', user);

      /**
       * fetch onboarding and save it
       */
      const onboarding = await getOnboarding(user._id);
      storageSet('onboarding', onboarding);
      

      /**
       * get main from storage first
       * when region changed => stroage main => db
       */
      const main = storageGet('main') || _.get(onboarding,'main');

      if(main){
        storageSet('main', main);
      }

      /* reset phone and login mode after logged in  */
      dispatch({ type: ActionType.TOGGLE_MODE, mode: 'phoneMode' });
      dispatch({ type: ActionType.SAVE_PHONE, phone: '' });

      const path = await jumpTo()
      dispatch(push(path));

      return path;

    } catch (error) {

      dispatch(showError(error.message));

    } finally {
      dispatch({ type: ActionType.HIDE_LOADING });
    }
  };
}


/**
 * request vcode
 */
export function confirmLogin(data) {

  const { phone } = data;

  return async(dispatch) => {

    /* show loading */
    dispatch({ type: ActionType.SHOW_LOADING });
    dispatch({ type: ActionType.SAVE_PHONE, phone });

    try {

      await checkLogin({ 
        phone, 
        sender: 'Weee!'
        // method: _.get(data, 'method', 'sms') 
      });

      /* if come from login page navigate to vcode  */
      if (data.from === 'phoneMode') {

        dispatch({ type: ActionType.TOGGLE_MODE, mode: 'Vcode' });
      } else {

        dispatch({ type: ActionType.RESTART_COUNTDOWN, restart: true });
      }


    } catch (error) {

      dispatch(showError(error.message));

      /* if error code is too-many-request should navigate to
        vcode and restart the internal function with params in response
        */
      if (error.code === 'too-many-requests') {
        dispatch({ type: ActionType.TOGGLE_MODE, mode: 'Vcode' });

        dispatch({
          type:          ActionType.VCODE_RESTART_TIME,
          countdown:     error.details.remaining
        });
      }

    
    } finally {
      dispatch({ type: ActionType.HIDE_LOADING });
   
    }
  };
}
