import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import HomeIcon from '@material-ui/icons/Home';
import { storageGet, storageClear } from '../utils/storage';
import  uuidv4  from 'uuid/v4';
import TranslateIcon from '@material-ui/icons/Translate';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import { changeLanguage } from '../containers/home/state/actions';
import lang from '../utils/filter/lang';
import LiveHelpIcon from '@material-ui/icons/LiveHelp';
import Support from './dialog/view/Support'
import { showSupportModal } from '../components/dialog/state/actions';
import { logOut } from '../containers/login/state/actions';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  title: {
    flexGrow: 1,
  },
  home:{
    'text-decoration':'none',
    color: '#fff',
    flexGrow: 1,
    display: 'block'
  },
  fr: {
    float: 'right'
  },
  logo: {
    'width': '13rem',
    paddingTop: theme.spacing(0.8),
  }
}));

function ButtonAppBar(props) {

  const classes = useStyles();
  const handleClick = () => {
    storageClear({})
    //render ui
    props.actions.logOut();
  }
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedLanguage, setSelectedLanguage] = React.useState(props.language || 'en-US');
  const open = Boolean(anchorEl);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = async (e) => {
    const {language} = e.currentTarget.dataset

    /* change language */
    if(language){

      await props.actions.changeLanguage(language)
      setSelectedLanguage(language)
    }
    setAnchorEl(null);
  };
  const showModal = async () => {
    await props.actions.showSupportModal()
  }
  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar>
          <a href="/#/home" className={classes.home}>
            {/* <HomeIcon /> */}
            <img src="https://img06.weeecdn.com/rtg/image/529/548/BCC6D144BA22DE2.png" alt='logo' key={uuidv4()} className={classes.logo}/>
          </a>
          <div>

          {
            props.user ? 
            <IconButton
              aria-label="customer-support"
              aria-haspopup="true"
              onClick={showModal}
              color="inherit"
            >
              <LiveHelpIcon fontSize="small"/>
            </IconButton> :
            null
          }
            <IconButton
              aria-label="translate"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
            >
              <TranslateIcon fontSize="small"/>
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={open}
              onClose={handleClose}
            >
              <MenuItem onClick={handleClose} selected={selectedLanguage === 'en-US'} data-language='en-US'>English</MenuItem>
              <MenuItem onClick={handleClose} selected={selectedLanguage === 'zh-CN'} data-language='zh-CN'>简体中文</MenuItem>
              <MenuItem onClick={handleClose} selected={selectedLanguage === 'zh-HK'} data-language='zh-HK'>繁体中文</MenuItem>
            </Menu>
          </div>
          {
            props.user ? 
            <Button variant="text" color="inherit" onClick={handleClick}>{lang('logout')}</Button> :
            <Button variant="outlined" color="inherit"  href="/#/login" >{lang('login')}</Button> 
          }
        </Toolbar>
        <Support
            title={lang('support')}
            content={lang('support-content')}
        />
      </AppBar>
    </div>
  );
}

/**
 * mapDispatchToProps
 */
function mapDispatchToProps(dispatch) {

  return {
    actions: bindActionCreators(
      {
        changeLanguage,
        showSupportModal,
        logOut
      },
      dispatch
    )
  };
}

const header = connect(
  state => ({ ...state.login,...state.home }),
  mapDispatchToProps,
)(ButtonAppBar);

export default header;