import * as ActionType from '../../../actions/actionTypes';
import { storageGet } from '../../../utils/storage';

const user = storageGet('user')
const initialState = { loginMode: 'phoneMode' };
if(user){
  initialState.user = user
}

export default (state = initialState, payload) => {

  switch (payload.type) {

    /* save user  will move to profile later*/
    case ActionType.SAVE_USER:

      return {
        ...state,
        user: payload.user
      };

    /* save phone*/
    case ActionType.SAVE_PHONE:
      return {
        ...state,
        phone: payload.phone
      };

    /* clear vcode */
    case ActionType.TOGGLE_MODE:
      return {
        ...state,
        loginMode: payload.mode
      };

    /* after resend , restart time */
    case ActionType.VCODE_RESTART_TIME:
      return {
        ...state,
        countdown: payload.countdown
      };

      /* after resend , restart time */
    case ActionType.RESTART_COUNTDOWN:
      return {
        ...state,
        restart: payload.restart
      };

    default:
      return state;
  }
};
