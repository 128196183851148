import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'

import login from '../containers/login/state/reducer';
import notification from '../containers/notification/state/reducer';
import basic from '../containers/basicInfo/state/reducer';
import home from '../containers/home/state/reducer';
import loading from './loading';
import support from '../components/dialog/state/reducer'


const rootReducer = (history) => combineReducers({
  router: connectRouter(history),
  login,
  home,
  notification,
  basic,
  loading,
  support
})
export default rootReducer;
