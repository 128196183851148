import _ from 'lodash';
import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { storageGet } from './storage';

/**
 * private rote
 */
const PrivateRouter = ({ component: Component, ...rest }) => {


  /* get user from local storage */
  const user = storageGet('user');

  const token = _.get(user, 'token');

  /* if token exist , it is login  */
  const isAuthenticated = !_.isEmpty(token);

  let flag = true;
  let redirectAddress = '/main';

  if (rest.path === '/login') {
    if (isAuthenticated) {
      flag = false;
      redirectAddress = '/home';
    }
  } else {
    if (!isAuthenticated) {
      flag = false; 
    }
  }

  if(rest.path === '/main') flag = true;

  return (
    <Route {...rest} render={(props) => (
      flag  ?
        <Component {...props} /> :
        <Redirect to={redirectAddress} />
    )} />
  );
};


export default PrivateRouter;
