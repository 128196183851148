/* Framework */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { PropTypes } from 'prop-types';

/* Modules */
import _ from 'lodash';

/* Actions */
import { closeErrorNotification } from '../state/actions';

/* Component */
// import ErrorModal from '../../../components/error/view/error';
import AlertModal from '../../../components/error/view/alert';


/**
 * Notification class
 */
export class Notification extends Component {

  /**
   * propTypes
   */
    static propTypes = {
      children:              PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.array
      ]),
      notification:           PropTypes.object.isRequired,
      closeErrorNotification: PropTypes.func.isRequired
    };

    /**
     * state
     */
    state={  }


    /**
     * render
     */
    render() {


      return (
        <div style={{ width: '100%' }}>
          {this.props.children}
          <AlertModal
            errorModalState={_.get(this.props, 'notification.errModalState')}
            closeModal={() => { this.props.closeErrorNotification(); }}
          />
        </div>


      );
    }

}

const NotificationPage = connect(
  state => ({ notification: state.notification }),
  dispatch => bindActionCreators({ closeErrorNotification }, dispatch)
)(Notification);

export default NotificationPage;
