import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

export  function Loading(props) {
  const classes = useStyles();

  return (
    <div>
      <Backdrop className={classes.backdrop} open={props.loading.showLoading} >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
}

const LoadingPage = connect(
  state => ({ loading: state.loading }),
  dispatch => bindActionCreators({  }, dispatch)
)(Loading);

export default LoadingPage;