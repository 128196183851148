import * as ActionType from '../../../actions/actionTypes';
import intl from 'react-intl-universal';
import { storageSet } from '../../../utils/storage';
const locales = {
  'en-US': require('../../../config/i18n/en-US.json'),
  'zh-CN': require('../../../config/i18n/zh-CN.json'),
  'zh-HK': require('../../../config/i18n/zh-HK.json')
};

/**
 * show error
 */
export const showError = (msg,severity = 'error') => async(dispatch, getState) => {

  dispatch({
    type:          ActionType.SHOW_ERROR,
    errModalState: { visible: true, msg, severity }
  });

};

/**
 * change language
 */
export const changeLanguage = (data) => async(dispatch, getState) => {

  /* change language & save it to redux persist */
  dispatch({ type: ActionType.CHANGE_LANGUAGE, language: data });

  /* set i18n local language*/
  intl.init({
    currentLocale: data,
    locales
  });

  storageSet('lang', data);
};