import request from '../utils/request';
import env from '../config/env';

/**
 * open ticket
 */
export async function openTicket(data) {

  const result = await request({
    url:    `${env.server}/v1/tickets`,
    method: 'post',
    data
  });

  return result;
}


