
/**
 * set data to local storage
 */
export function storageSet(name, data) {

  localStorage.setItem(name, JSON.stringify(data));
}

/**
 * get data from local storage
 */
export function storageGet(data) {

  const result = localStorage.getItem(data);

  try {
    return JSON.parse(result);
  } catch (e) {
    return result;
  }
}

/**
 * clear storage
 */
export function storageClear({data,all = false}) {

  if(all){
    localStorage.clear();
  }else{
    if(data){
      localStorage.removeItem(data);
    }else{
      localStorage.removeItem('user');
      localStorage.removeItem('onboarding');
      localStorage.removeItem('main');
    }
  }
}