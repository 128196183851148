import * as ActionType from '../../../actions/actionTypes';



/**
 * closeErrorNotificationAction
 */
export function closeErrorNotification() {

  return async(dispatch) => {

    dispatch({ type: ActionType.HIDE_ERROR });
  };
}

/**
 * show error
 */
export const showError = (msg) => async(dispatch, getState) => {

  dispatch({
    type:          ActionType.SHOW_ERROR,
    errModalState: { visible: true, msg }
  });

};
