import * as ActionType from '../../../actions/actionTypes';
import { storageGet } from '../../../utils/storage';
import { showError } from '../../../containers/home/state/actions';
import { openTicket } from '../../../services/support';
import lang from '../../../utils/filter/lang';


/**
 * show support modal
 */
export function showSupportModal() {

  return async(dispatch) => {

    dispatch({ 
      type: ActionType.SHOW_SUPPORT_MODAL,
      supportModalState: { visible: true } 
    });
  };
}

/**
 * hide support modal
 */
export const hideSupportModal = () => async(dispatch, getState) => {

  dispatch({
    type:          ActionType.HIDE_SUPPORT_MODAL,
    supportModalState: { visible: false }
  });

};

/**
 * customer support
 */
export const customerSupport = ({subject,type}) => async(dispatch, getState) => {

  /* show loading */
  dispatch({ type: ActionType.SHOW_LOADING });

  try {
    
    const user = storageGet('user');
    const data = {
      subject,
      type,
      phone: user.phone
    }
    
    await openTicket(data);
    dispatch(showError(lang('success'),'success'));
  } catch (error) {

    dispatch(showError(error.message));

  } finally {
    dispatch({ type: ActionType.HIDE_LOADING });
  }

};