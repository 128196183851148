import _ from 'lodash';
import * as ActionType from '../../../actions/actionTypes';
import { push } from 'connected-react-router';
import { showError } from '../../home/state/actions';
import { getOnboarding, updateOnboarding } from '../../../services/onboarding';
import { storageSet,storageGet } from '../../../utils/storage';
import  jumpTo from '../../../utils/jumpTo'

/**
 * get onboarding info
 */
export const getOnboardingInfo = userId => async(dispatch, getState) => {

   /* show loading */
   dispatch({ type: ActionType.SHOW_LOADING });

   try {

    const onboarding = await getOnboarding(userId);
    storageSet('onboarding', onboarding);

    dispatch({ type: ActionType.SAVE_ONBOARDING, onboarding });

   } catch (error) {

    dispatch(showError(error.message));

   } finally {
    dispatch({ type: ActionType.HIDE_LOADING });
   }

};

/**
 * update onboarding info
 * not need to jump when update region data
 */
export const updateOnboardingInfo = ({data,isJump = true}) => async(dispatch, getState) => {

  /* show loading */
  dispatch({ type: ActionType.SHOW_LOADING });

  try {
    
    const id = _.get(storageGet('onboarding'),'_id');

    if(!id){
      throw new Error('You may have an account not registered through onboading')
    }
    
    const onboarding = await updateOnboarding(data,id);
    storageSet('onboarding', onboarding);

    dispatch({ type: ActionType.SAVE_ONBOARDING, onboarding });

    if(isJump){
      const path = await jumpTo()
      dispatch(push(path));
    }
 
  } catch (error) {

    dispatch(showError(error.message));

  } finally {
    dispatch({ type: ActionType.HIDE_LOADING });
  }

};