import _  from 'lodash';
import * as ActionType from '../../../actions/actionTypes';
import { storageGet } from '../../../utils/storage';

const initialState = { language: storageGet('lang') || 'en-US' };

export default (state = initialState, payload) => {

  switch (payload.type) {
    
    case ActionType.CHANGE_LANGUAGE:
      return {
        ...state,
        language: payload.language
      };
    default:
      return state;
  }
};
