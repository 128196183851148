import React, { Component } from 'react';
import ButtonAppBar from '@/components/ButtonAppBar';
import Copyright from '@/components/Copyright';
import Notification from '@/containers/notification/view/notification';
import Box from '@material-ui/core/Box';
import { Switch,  Redirect } from 'react-router-dom'
import routers from '@/routers/index';
import PrivateRouter from './utils/priviteRouter';
import _ from 'lodash';
import  uuidv4  from 'uuid/v4';
import Loading from './components/loading/view/loading';
import './App.css';
import { renewToken } from './services/login';
import { storageGet, storageSet, storageClear } from './utils/storage';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getOnboardingInfo } from './containers/basicInfo/state/actions';

import intl from 'react-intl-universal';
const locales = {
  'en-US': require('./config/i18n/en-US.json'),
  'zh-CN': require('./config/i18n/zh-CN.json'),
  'zh-HK': require('./config/i18n/zh-HK.json')
};

class App extends Component {

  state = {
    initDone: false
  }

  /**
   * component did mount
   */
  async componentWillMount() {

    await this.renew();
    await this.loadLocales();
  }

  renew = async () => {


    /* renew token */
    if (_.get(storageGet('user'), 'token')) {
      
      try {

        /* Renew token and save */
        const user = await renewToken();

        storageSet('user', user);

        /* Renew onboarding info and save */
        await this.props.getOnboardingInfo(user._id);

      } catch (error) {

        const { code } = error;

        /* if auth-failed logout */
        if (code === 'auth-failed') {
          storageClear({})
        }
      }

    } else {
      storageClear({})
    }

  }

    /**
   * i18n
   */
  loadLocales = async () => {

    /* init method will load CLDR locale data according to currentLocale
    react-intl-universal is singleton, so you should init it only once in your app*/
    const lang =  storageGet('lang');

    let initLang = 'en-US';

    if (!_.isEmpty(lang)) {
      initLang = lang;
    } else {

      /* set language to local storage */
      storageSet('lang', lang);
    }

    intl.init({
      currentLocale: initLang,
      locales
    }).then(() => {
      this.setState({ initDone: true });
    });
  }

  renderRouter = () => {

    const routerArr = _.map(routers, (r, key) => (

      <PrivateRouter 
        key={uuidv4()} 
        component={r.component}
        exact={!!r.exact}
        path={r.path}
      />
    ));

    routerArr.push(<Redirect key={uuidv4()} to="/main" />);

    return routerArr;
  }

  render() {
    // console.log('app props =====>',this.props)
    return (
      this.state.initDone && 
      <Notification>
        <Loading />
        <ButtonAppBar/>
        <Switch>
            {
              this.renderRouter()
            }
        </Switch>
        <Box mt={8}>
          <Copyright />
        </Box>
      </Notification>
    );
  }
}
const AppPage = connect(
  state => ({ ...state.basic }),
  dispatch => bindActionCreators({ getOnboardingInfo }, dispatch)
)(App);
export default AppPage;
