import React from 'react';


/**
 * routers
 */
export default function(Loadable) {
  return [
    {
      name:      'home',
      path:      '/home',
      exact:     true,
      component: Loadable({
        loader:  () => import('../containers/home/view/home'),
        loading: () => <div />
      })
    }
  ];
}
